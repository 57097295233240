// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js'; import '../blocks/elementor/home-hero-repeater/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  var video = $('.home-hero .elementor-widget-video .elementor-video');

  // Check if the video element exists on the page
  if (video.length) {
    // Set autoplay, muted, and playsinline attributes
    video.attr({
      autoplay: true,
      muted: true,
      playsinline: true,
    });

    // Remove the controls attribute to hide the play button
    video.removeAttr('controls');
    video.removeAttr('controlslist');

    // Check if autoplay is blocked (macOS might block autoplay), if so, require user interaction
    if (video[0].paused) {
      // Add a click event listener to the .media-controls-container button
      $('.media-controls-container .media-controls.mac button').on(
        'click',
        function () {
          setTimeout(function () {
            // Play the video after the delay
            video[0].play();
          }, 1500); // 1500 milliseconds = 1.5 seconds
        }
      );
    } else {
      // Autoplay the video if it isn't blocked
      video[0].play();
    }
  }

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });
  blockScrollOnMobMenu();
  addMarginIfLoggedIn();
  hideTopbar();
  resizeVideo();
  circleTabs();
  calcVisibleExcerptLines();
  headerTabsOpenOnHover();
  headerTabsLinkWrap();
});
// eslint-disable-next-line no-unused-vars
function headerTabsOpenOnHover() {
  console.log('function working');
  $('.header-tabs .e-n-tab-title').on('mouseenter', function () {
    $(this).trigger('click'); // Trigger click on hover
  });
}
function headerTabsLinkWrap() {
  let tabTitles = $('.header-tabs .e-n-tab-title');

  if (tabTitles.length > 0) {
    tabTitles.each(function () {
      let title = $(this);
      let link = $(
        '.header-tabs .e-n-tabs-content div[aria-labelledby="' +
          title.attr('id') +
          '"]'
      ).attr('tab-link');

      if (!link || link.length === 0) {
        link = '#';
      }

      // Create a new anchor element
      let anchor = $('<a></a>').attr('href', link).addClass('tab-link');

      // Clear the button's text and append the anchor inside it
      title.append(anchor);
      let permalink = $('.tab-link', title);
      permalink.click(function () {
        window.location.href = link;
      });
    });
  }
}
function calcVisibleExcerptLines() {
  let cards = document.querySelectorAll('.post-card');
  if (cards.length > 0) {
    cards.forEach(function (card) {
      let cardContent = card.querySelector('.post-card-content');
      let excerptWrapper = card.querySelector('.post-card-excerpt');
      cardContent.style.height = '100%';
      cardContent.style.maxHeight = '100%';
      excerptWrapper.style.maxHeight = 'none';
      let wrapperHeight = excerptWrapper.clientHeight;
      excerptWrapper.removeAttribute('style');
      cardContent.removeAttribute('style');
      //console.log(wrapperHeight);
      let exceprtText = excerptWrapper.querySelector('p');
      let lineHeight = parseFloat(getComputedStyle(exceprtText).lineHeight);
      if (!isNaN(lineHeight)) {
        let visibleLines = Math.floor(wrapperHeight / lineHeight);

        // Set CSS styles directly
        exceprtText.style.webkitLineClamp = visibleLines;
        //console.log(`Visible lines set to: ${visibleLines}`); // Debugging log
      }
    });
  } else {
    ////console.error('No post cards found.');
  }
}

// eslint-disable-next-line no-unused-vars
function calcVisibleExcerptLines1() {
  let containers = document.querySelectorAll('.post-card .post-card-excerpt');
  if (containers.length > 0) {
    containers.forEach(function (card) {
      let excerpt = card.querySelector('p'); // Ensure this selector is correct
      if (excerpt) {
        // Get the height of the container
        let height = card.clientHeight;
        let lineHeight = parseFloat(getComputedStyle(excerpt).lineHeight);

        if (!isNaN(lineHeight)) {
          let visibleLines = Math.floor(height / lineHeight);

          // Set CSS styles directly
          excerpt.style.display = '-webkit-box';
          excerpt.style.webkitBoxOrient = 'vertical';
          excerpt.style.overflow = 'hidden';
          excerpt.style.webkitLineClamp = visibleLines;
          excerpt.style.height = `${visibleLines * lineHeight}px`; // Set height explicitly

          //console.log(`Visible lines set to: ${visibleLines}`); // Debugging log
        } else {
          //console.error('Invalid line height:', lineHeight);
        }
      } else {
        //console.error('No excerpt found for this container:', card);
      }
    });
  } else {
    //console.error('No post cards found.');
  }
}
function blockScrollOnMobMenu() {
  let body = $('body');
  let mobButton = $('.header-menu-mob .e-n-menu-toggle-icon');
  if (mobButton) {
    mobButton.each(function () {
      this.addEventListener('click', function () {
        body.toggleClass('prevent-scroll');
      });
    });
  }
}
function addMarginIfLoggedIn() {
  let adminBar = $('#wpadminbar');
  let header = $('.header');
  if (header && adminBar && $(window).width() > 640) {
    let margin = adminBar.height();
    // //console.log(margin);
    $('.header').css('margin-top', margin + 'px');
  }
}
function hideTopbar() {
  let topBar = $('#header-topbar');
  if (topBar) {
    if (window.scrollY > 0) {
      topBar.addClass('topbar-hidden');
    } else {
      topBar.removeClass('topbar-hidden');
    }
  }
}
function circleTabs() {
  let tabs = $('.circle-tabs');
  if (tabs && $(window).width() > 1024) {
    tabs.each(function () {
      let tabWheel = $('.e-n-tabs-heading', this);
      tabWheel.css('transform', 'rotate(' + 23 + 'deg)');
      let titles = $('.e-n-tab-title', this);
      let items = $('.e-n-tabs-content > div', this);
      let returnButtonTemplate = document.createElement('div');
      returnButtonTemplate.className = 'tab-return-button';
      tabWheel.append(returnButtonTemplate);
      let returnButton = $('.e-n-tabs-heading .tab-return-button', this);
      let zeroItem = titles[0];
      let moveButtons = $('.move-button', this);
      moveButtons.each(function () {
        this.addEventListener('click', function () {
          event.preventDefault();
          titles[1].click();
        });
      });
      //console.log(returnButton);
      //console.log(zeroItem);
      returnButton[0].addEventListener('click', function () {
        zeroItem.click();
        // let zeroAngle = zeroItem.getAttribute('angle');
        tabWheel.css('transform', 'rotate(' + 23 + 'deg)');
        titles.each(function () {
          $(this).css('transform', 'rotate(' + -23 + 'deg)');
        });
      });
      let angle;
      titles.each(function () {
        this.addEventListener('click', function () {
          angle =
            items[this.getAttribute('data-tab-index') - 1].getAttribute(
              'angle'
            );
          if (!angle) {
            angle = 0;
          }
          tabWheel.css('transform', 'rotate(' + angle + 'deg)');
          titles.each(function () {
            $(this).css('transform', 'rotate(' + angle * -1 + 'deg)');
          });
        });
      });
    });
  }
}

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  hideTopbar();
});
